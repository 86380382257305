import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="logo-container">
        <div className="logo-background" />
      </div>

      <h1>Changing the way education works...</h1>
      <h2>Soon...</h2>
    </div>
  );
}

export default App;
